@import "../../../assets/styles/colors";

.SpeakersIndex {
  width: 100%;
  min-height: 100vh;
  background-color: $white;
  .actionButtonsContainer {
    //   width: 150px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .custom-select {
      height: calc(1.5em + 0.75rem + 2px) !important;
      line-height: 1 !important;
  }

  .excelButton {
      width: 38px;
      height: 38px;
  }

  .opacity-60 {
      opacity: 0.6;
  }
  
  .image-container {
      height: 200px;
      .image {
          height: 100%;
          margin: 0 auto;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
      }
  }
  .speakers-index {
      background-color: transparent;
  }
  .color-purple {
      color: #52297C;
  }
  .logo-icon {
      position: absolute;
      right: 23%;
  }
  .product-container {
      .stock-text {
          font-family: NexaBd, sans-serif !important;
      }
  }
  @media screen and (min-width: 700px) and (max-width: 1400px) {
      .image-container {
          height: 100px;
      }
      .market-button{
          font-size: 12px;
      }
  }
  .product-detail-cont {
      white-space: nowrap;
      max-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
          max-height: 500px;
          white-space: unset;
          overflow:visible;
          text-overflow:unset;
      }
  }
  .hide {
      visibility: hidden;
  }
  thead {
    //   display: block;
  }
  .overlay-top {
      position: absolute;
      background-color: rgba($color: black, $alpha: 0.2);
      width: 100%;
      top: 1%;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      .square {
          width: 90px;
          background-color: rgba($color: red, $alpha: 0.2);
          transform: rotate(45deg);
          border-radius: 10px;
          border: 2px solid red;
          height: 90px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          text-align: center;
          p {
              transform: rotate(-45deg);
              position: relative;
              top: 35%;
              left: 5%;
              color: red;
              text-align: center;
              border-radius: 10px;
          }
      }
  }
  .content-header {
      min-height: 160px;
      h1 {
          font-size: 250%;
          span {
              font-size: 70%;
              span {
                  font-size: 110%;
                  font-family: NexaBd, sans-serif !important;
              }
          }
      }
      @media screen and (max-width: 500px) {
          min-height: 100px;
      }
  }
  #ex2 .fa-stack[data-count]:after {
      position:absolute;
      right:0%;
      top:20%;
      content: attr(data-count);
      font-size:20%;
      padding:.6em;
      border-radius:999px;
      line-height:.75em;
      color: white;
      background: $cyan;
      text-align:center;
      min-width:2em;
      font-weight:bold;
  }
  #ex2 {
      width: 80px;
      display: flex;
      align-items: center;
      position: fixed;
      z-index: 300;
      right: 0;
      .circle {
          width: 70px;
          color: $blue;
      }
      .shop {
          width: 30px;
      }
  }
  .button-top {
      border-radius: 5px 5px 0 0 !important;
  }
  .button-center {
      border-radius: 0 !important;
  }
  .button-bottom {
      border-radius: 0 0 5px 5px !important;
  }
}

.SpeakerModal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 80px;
  max-height: 100vh;
  overflow-y: auto;
  @media screen and (max-width: 500px) {
    padding: 10px;
  }
  .card-custom {
    max-height: 95vh;
    overflow-y: auto;
    @media screen and (min-width: 300px) and (max-width: 500px) {
      min-width: 95vw;
    }
    @media screen and (min-width: 501px) and (max-width: 767px) {
      min-width: 85vw;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      min-width: 95vw;
    }
    @media screen and (min-width: 1201px) and (max-width: 1500px) {
      min-width: auto;
    }
    @media screen and (min-width: 1501px) {
      min-width: auto;
    }
  }
  .image-holder {
    overflow: hidden;
    .image-display {
      width: 100%;
    }
  }
}