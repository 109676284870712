.RegisterForm {
}

.PhoneInput {
  .PhoneInputInput {
    height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
  }

}