$magenta: #662482;
$pink: #E4A3C2;
$blue: #254472;
$cyan: #84B6A5;
$mentita-blue: #873597;
$green: #008000;
$red: #ff0000;
$dark: #000000;

$dark-gray: #303030;
$gray: #F7F7FA;
$claro-red: #F3F6F9;
$main-highlight: $claro-red;
$dim-highlight: #db5d50;
$orange: orange;
$blue: #29abe2;
$light-highlight: #ff9a9a;
$light-background: $gray;
$muted-color: #8f8f8f;
$text-color: black;
$dark-title: #141422;
$white: white;

$input-border-color: #dadada;
$question-border: #979797;
$step-background: #ECECED;
$button-color: #CCC;
$card-border-color-hover: $claro-red;
$success: #4ad271;
