@import "../../../../../assets/styles/colors";

.PaymentFormGeneral {
  width: 100%;
  height: auto;
  
  .card {
    border: 1px solid #E4E6EF;
    border-radius: 5px;
    margin: 1rem 0rem !important;
    padding: 1rem !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .total {
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1rem;
  }

  .events-block{
    &>div{
      padding: 0 !important;
    }
  }
}