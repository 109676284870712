@import "../../../assets/styles/colors";

.RegistersIndex {
    width: 100%;
    min-height: 100vh;
    background-color: $white;

    .card {
        margin: 0px;
        padding: 0px;
    }
}