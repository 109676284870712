.LoadingNotification {
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: flex;
    align-items: center;
    background-color: white;
    width: 200px;
    border: 2px solid #29abe6;
    border-radius: 10px;
    z-index: 10000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    .load-icon {
        width: 50px;
    }
}