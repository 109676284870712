@import "../../assets/styles/measures.scss";
@import "src/assets/styles/colors";

.DashLayout{
    background-color: #f3f3f3;
    min-height: 100vh;
    @media screen and (min-width: 700px) {
      .header-mobile {
        display: none;
      }
    }
    .logo-icon {
      width: 70px;
      margin-top: 15px;
    }
    @media screen and (min-width: 700px) {
      .ResponsiveMenu {
        display: none;
      }
      .responsive-burguer {
        position: fixed;
      }
    }
    .main-content{
      width: calc(100% - #{$menu-width});
      float: right;
      min-height: 100vh;
      transition: 1s ease-in-out;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
    .hidden {
      width: calc(100% - #{$menu-hidden-width});
      float: right;
      min-height: 100vh;
      transition: 1s ease-in-out;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
    .dash-screen{
      padding: 2em 2em 2em;
    }
    @media screen and (max-width: 700px) {
      .responsive-burguer {
        position: fixed;
        right: 30px;
        zoom: 150%;
      }
    }
    .menu-item-shortcut {
      margin: 0.5em 0;
      max-width: 15vw;
      float: right;
      margin-right: 6vw;
      @media screen and (max-height: 800px) {
        margin: 0.2em 0;
      }
      border-radius: $border-radius-large;
      cursor: pointer;
      border: 1px solid $dark-gray;

      .menu-link {
        min-height: 3.2em;
        padding: 0 0.6em;
        display: block;
        text-decoration: none;
        color: $dark-gray;
        font-size: 15px;
        @media screen  and (max-height: 750px){
          font-size: 12px;
        }
        .text-box {
          margin-left: 1em;
          // transition: 1s ease-in-out;
        }
        .font-mini {
          font-size: 1px;
          opacity: 0;
          transition: 0s;
          position: absolute;
        }
      }

      &.active, &:hover {
        background-color: $blue;
        
        .menu-link {
          color: $white;

          .active-icon {
            display: inline;
          }

          .dashboard-logo {
            display: none;
          }
        }
      }

      .menu-icon {
        margin-right: 0.3em;
      }
    }
  }
  