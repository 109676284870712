@import "src/assets/styles/colors";

.TideReactTable{
  position: relative;
  background: white;
  padding: 10px 30px;
  font-family: 'NexaLg';


  .table-container{
    min-height: 500px;
  }
  .table {
    border-bottom: 1px solid lightgrey;
    th {
      border-top: none;
      border-bottom-width: 1px;
      color: $blue;
      font-weight: bold;
    }
    td{
      border-top: none;
      padding: .3rem;
      font-size: 14px;
    }
  }
  .table-footer{
    display: block;
    font-size: 0.8em;
    .register-count{
      float: left;
      color: $muted-color;
    }
  }
  .spacer{
    height: 10em;
  }
  .over-message{
    font-size: 1.6em;
    text-align: center;
    color: $muted-color;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -2em;
    width: 100%;
    span{
      display: inline-block;
      background-color: $red;
      border-radius: 10px;
      padding: 20px;
      color: white;
    }
    .LoadingIcon{
      max-width: 100px;
      margin: auto;
    }
  }
  .table td, .table th {
    vertical-align: middle;
  }

  .table tr:hover{
    background-color: $light-background;
  }
  .table thead tr:hover{
    background-color: initial;
  }
}
