@import "../../../../assets/styles/measures.scss";
@import "src/assets/styles/colors";
@import "src/assets/fonts/fonts";

.MenuSidebar {
  height: 100vh;
  position: fixed;
  padding-top: 1em;
  left: 0;
  transition: 1s ease-in-out;
  top: 0;
  z-index: 300;
  background: $gray;
  border-right: 1px solid #EBEDF3;
  overflow: auto;

  &.hiddenAside {
    left: -($menu-width+170px) !important;
    transition: left 1s ease-in-out;
  }

  .menu-container{
    min-height: 100%;
    transition: width 1s ease-in-out;
    display: flex;
    flex-direction: column;
    position: relative;

    &.hide {
      width: $menu-hidden-width;
      padding: 0 1em 2em;
    }
    &.discover {
      width: $menu-width;
      padding: 0 1em 1em;
    }
  }

  .hiddenAside {
    border: 5px solid red;
  }
  .cls-1 {
      fill: #c9c9c9 !important;
  }
  .dashboard-logo {
    width: 35px;
    color: $dark !important;
  }
  .text-active-icon {
    color: $white;
  }
  .icon-dash-logo {
    font-size: 22px;
  }
  .profile-logo {
    width: 35px;
  }

  .active-icon {
    display: none;
    width: 35px;
  }

  .logo {
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #c9c9c9;
    text-align: center;
    justify-content: space-between;
    align-items: center;

    img,svg {
      width: 50%;
    }

    .display-sidebar-icon {
      width: 30px;
      cursor: pointer;
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
  }

  .close-product {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px;
    font-size: 0.8em;
    border-radius: 0;
  }

  .company-name {
    padding: 0 0 0 0;
    font-family: $font-bold;
    font-size: 1.2em;
    text-align: center;
  }

  .list-title {
    font-family: $font-bold;
    color: $muted-color;
  }
  .lists-container {
    flex: 1;
    padding-bottom: 20px;
    .logo-container {
      display: flex;
      justify-content: center;
      .logo-icon {
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  .menu-list {
    list-style: none;
    padding: 0;

    .logout-icon {
      font-size: 23px;
      color: $dark;
    }
    .logout-icon-active {
      font-size: 23px;
      color: $magenta;
    }

    .menu-item {
      margin: 0.5em 0;
      @media screen and (max-height: 800px) {
        margin: 0.2em 0;
      }
      border-radius: $border-radius-large;
      cursor: pointer;
      border: 1px solid $dark-gray;

      .menu-link {
        min-height: 3.2em;
        padding: 0 0.6em;
        display: block;
        text-decoration: none;
        color: $dark-gray;
        font-size: 15px;
        @media screen  and (max-height: 750px){
          font-size: 12px;
        }
        .text-box {
          margin-left: 1em;
          // transition: 1s ease-in-out;
        }
        .font-mini {
          font-size: 1px;
          opacity: 0;
          transition: 0s;
          position: absolute;
        }
      }

      &.active, &:hover {
        background-color: $blue;
        
        .menu-link {
          color: $white;

          .active-icon {
            display: inline;
          }

          .dashboard-logo {
            display: none;
          }
        }
      }

      .menu-icon {
        margin-right: 0.3em;
      }

    }

    .is-submenu {
      margin: 0.5em 0;
      @media screen and (max-height: 800px) {
        margin: 0.2em 0;
      }
      border-radius: $border-radius-large;
      cursor: pointer;
      border: 1px solid $dark-gray;

      .menu-link {
        min-height: 3.2em;
        padding: 0 0.6em;
        display: block;
        text-decoration: none;
        color: $dark-gray;
        font-size: 15px;
        @media screen  and (max-height: 750px){
          font-size: 12px;
        }
        .text-box {
          margin-left: 1em;
          // transition: 1s ease-in-out;
        }
        .font-mini {
          font-size: 1px;
          opacity: 0;
          transition: 0s;
          position: absolute;
        }
      }

      .menu-icon {
        margin-right: 0.3em;
      }
    }

    .is-submenu:hover {
      background-color: transparent !important;
    }

    .mr-2 {
      margin-right: 0.5rem;
    }

    // Submenu
    & li ul {
      display:none;
      position: relative;
      min-width:140px;
      height: auto;
      min-height: 50px;
      margin-left: 0.7rem;
    }
    
    & li:hover > ul {
        display:block;
        height: auto;
    }
    
    & li ul li {
        position:relative;
        margin-top: 7px;
        margin-bottom: 7px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:hover{
            background-color: $dark;
            color: $orange;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }
    }

    & li ul li a {
        text-decoration: none;
        display: block;
        padding: 5px 10px;
        transition: all 0.2s ease-in-out;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        color: $dark;
        font-family: Popppins-Regular;
        height: 37px;

        &:hover{
            background-color: transparent;
            color: white;
            font-weight: bold;
            border-radius: 5px;
        }
    }
    
    & li ul li ul {
        right:-140px;
        top:0px;
    }
  }

  .ShoppingDetails {
    .hidden-cont {
      background-color: transparent;
      img {
        opacity: 0;
      }
      .hidden-icon {
        font-size: 50%;
        transition: opacity .25s ease-in-out;
      }
    }
    .open-cont {
      opacity: 1;
      transition: opacity .25s ease-in-out;
    }
  }
  .app-version{
    position: absolute;
    bottom: 10px;
    left: 10px;
    opacity: 0.5;
    font-family: sans-serif !important;
  }
}
