.ImportPerson {
  width: 100%;
  height: 100%;

  .import-form {
    border: 1px solid #000000;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .import-label {
    font-size: 1rem;
    font-weight: 600;
  }

  .modal-container {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 80px;
    max-height: 100vh;
    overflow-y: auto;
    @media screen and (max-width: 500px) {
      padding: 10px;
    }
    .card-custom {
      max-height: 95vh;
      overflow-y: auto;
      @media screen and (min-width: 300px) and (max-width: 500px) {
        min-width: 95vw;
      }
      @media screen and (min-width: 501px) and (max-width: 767px) {
        min-width: 85vw;
      }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        min-width: 95vw;
      }
      @media screen and (min-width: 1201px) and (max-width: 1500px) {
        min-width: auto;
      }
      @media screen and (min-width: 1501px) {
        min-width: auto;
      }
    }
    .image-holder {
      overflow: hidden;
      .image-display {
        width: 100%;
      }
    }
  }
}